import "core-js/modules/es6.regexp.to-string";
import request from '@/utils/request';
import { Loading } from 'element-ui';

// 域名状态管理 - 删除
export function replaceDomain(data) {
  return request({
    url: '/domain/replaceDomain',
    method: 'post',
    data: data
  });
}

// 域名状态管理 - 删除
export function editDomainDeleteStatus(data) {
  return request({
    url: '/domain/editDomainDeleteStatus',
    method: 'post',
    data: data
  });
}

// 被拦截ip列表-新增数据
export function addBlackList(data) {
  return request({
    url: '/blackList/addBlackList',
    method: 'post',
    data: data
  });
}

// 被拦截ip列表-删除数据
export function deleteBlackListById(data) {
  return request({
    url: '/blackList/deleteBlackListById',
    method: 'post',
    data: data
  });
}

// 查询被拦截ip列表
export function queryList(data) {
  return request({
    url: '/blackList/queryList',
    method: 'post',
    data: data
  });
}

// 查询检查模版列表
export function queryTemplateList(data) {
  return request({
    url: '/checkTemplate/queryList',
    method: 'post',
    data: data
  });
}

// 修改检查模版
export function updateCheckTemplateById(data) {
  return request({
    url: '/checkTemplate/updateCheckTemplateById',
    method: 'post',
    data: data
  });
}

// 查询域名列表
export function queryDomainList(data) {
  return request({
    url: '/domain/queryDomainList',
    method: 'post',
    data: data
  });
}

// 查询域名列表
export function getDomainList(data) {
  return request({
    url: '/domain/getDomainList',
    method: 'post',
    data: data
  });
}

// 修改自动检测状态接口
export function updateAutoCheckStatusById(data) {
  var loadingInstance = Loading.service({
    lock: true,
    // target: '.main-container',
    text: data.autoCheckStatus.toString() === '1' ? '开启自动检测中' : '关闭自动检测中',
    spinner: 'el-icon-loading',
    background: 'rgba(0, 0, 0, 0.7)'
  });
  return request({
    url: '/domain/updateAutoCheckStatusById',
    method: 'post',
    data: data
  }).then(function (_) {
    loadingInstance.close();
    return _;
  }).catch(function (_) {
    loadingInstance.close();
    return _;
  });
}

// 修改自动检测状态接口
export function batchDomainStatusByDgulpId(data) {
  return request({
    url: '/dgulpDomain/batchDomainStatusByDgulpId',
    method: 'post',
    data: data
  });
}

// 修改域名启用状态(启用或停用)接口
export function updateDomainStatusById(data) {
  return request({
    url: '/domain/updateDomainStatusById',
    method: 'post',
    data: data
  });
}

// 修改域名自动检测时间
export function editDomain(data) {
  return request({
    url: '/domain/editDomain',
    method: 'post',
    data: data
  });
}

// 新增域名方案
export function addScheme(data) {
  return request({
    url: '/scheme/addScheme',
    method: 'post',
    data: data
  });
}

// 查询域名方案列表
export function querySchemeList(data) {
  return request({
    url: '/scheme/querySchemeList',
    method: 'post',
    data: data
  });
}

// 查询商户域名组列表
export function queryMerchantDomainList(data) {
  return request({
    url: '/scheme/queryAgentDomainGroup',
    method: 'post',
    data: data
  });
}

// 修改域名方案
export function updateSchemeById(data) {
  return request({
    url: '/scheme/updateSchemeById',
    method: 'post',
    data: data
  });
}

// 修改域名方案启用状态(启用或停用)
export function udpateSchemeStatusById(data) {
  return request({
    url: '/scheme/udpateSchemeStatusById',
    method: 'post',
    data: data
  });
}

// 删除域名方案
export function deleteSchemeById(data) {
  return request({
    url: '/scheme/deleteSchemeById',
    method: 'post',
    data: data
  });
}

// 查询域名组列表
export function queryDomainGroupListByType(_ref) {
  var schemeId = _ref.schemeId,
    type = _ref.type;
  return request({
    url: '/domainGroup/queryDomainGroupListByType',
    method: 'post',
    data: {
      schemeId: schemeId,
      type: type
    }
  });
}

// 修改域名组
export function updateDomainGroupById(data) {
  return request({
    url: '/domainGroup/updateDomainGroupById',
    method: 'post',
    data: data
  });
}

// 新增域名组
export function addDomainGroup(data) {
  return request({
    url: '/domainGroup/addDomainGroup',
    method: 'post',
    data: data
  });
}

// 省字段查询
export function queryDictList(data) {
  return request({
    url: '/provinceDict/queryList',
    method: 'post',
    data: data
  });
}
// 省字段查询
export function queryAreaInfo(data) {
  return request({
    url: '/domainGroupUserLevelProvince/queryAreaInfo',
    method: 'get',
    data: data
  });
}

// 域名组策略管理,查询域名组下的策略数据
export function queryListByGroupId(data) {
  return request({
    url: '/domainGroupUserLevelProvince/queryListByGroupId',
    method: 'post',
    data: data
  });
}

// 域名组策略管理 - 批量添加
export function addDomainGroupList(data) {
  return request({
    url: '/domainGroupUserLevelProvince/addList',
    method: 'post',
    data: data
  });
}

// 域名组策略管理 - 批量删除
export function deleteDomainGroupList(data) {
  return request({
    url: '/domainGroupUserLevelProvince/deleteList',
    method: 'post',
    data: data
  });
}

// 域名组策略管理 - 批量修改告警
export function editList(data) {
  return request({
    url: '/domainGroupUserLevelProvince/editList',
    method: 'post',
    data: data
  });
}

// 域名组策略管理 - 编辑警告值
export function editDomainGroupUserLevelProvince(data) {
  return request({
    url: '/domainGroupUserLevelProvince/edit',
    method: 'post',
    data: data
  });
}

// 设备管理接口
export function queryDeviceDictList(data) {
  return request({
    url: '/deviceDict/queryList',
    method: 'post',
    data: data
  });
}

// 用户等级
export function queryUserLevelList(data) {
  return request({
    url: '/userLevelDict/queryList',
    method: 'post',
    data: data
  });
}

// 域名池管理 - 查询域名列表数据(根据dgulpId)
export function queryDomainListByDgulpId(data) {
  return request({
    url: '/dgulpDomain/queryDomainListByDgulpId',
    method: 'post',
    data: data
  });
}

// 域名池管理 - 移除域名
export function deleteDgulpDomainById(data) {
  return request({
    url: '/dgulpDomain/deleteDgulpDomainById',
    method: 'post',
    data: data
  });
}

// 域名池管理 - 排序域名
export function editDomainSort(data) {
  return request({
    url: '/dgulpDomain/editDomainSort',
    method: 'post',
    data: data
  });
}

// 域名池管理 - 添加域名
export function addDomainPool(data) {
  return request({
    url: '/dgulpDomain/addDomain',
    method: 'post',
    data: data
  });
}

// 线路商管理 - 查询列表
export function queryRouteList(data) {
  return request({
    url: 'route/queryRouteList',
    method: 'post',
    data: data
  });
}

// 线路商管理 - 新增
export function addRoute(data) {
  return request({
    url: 'route/addRoute',
    method: 'post',
    data: data
  });
}

// 线路商管理 - 编辑
export function editRoute(data) {
  return request({
    url: 'route/editRoute',
    method: 'post',
    data: data
  });
}

// 线路商管理 - 状态
export function updateRouteStatus(data) {
  return request({
    url: 'route/updateRouteStatus',
    method: 'post',
    data: data
  });
}

// 域名状态管理 - 新增域名
export function addDomain(data) {
  return request({
    url: 'domain/addDomain',
    method: 'post',
    data: data
  });
}

// 图片域名管理 - 查询列表
export function pictureQueryList(data) {
  return request({
    url: 'pictureDomain/queryList',
    method: 'post',
    data: data
  });
}
// 图片域名管理 - 新增
export function addPictureDomain(data) {
  return request({
    url: 'pictureDomain/addPictureDomain',
    method: 'post',
    data: data
  });
}
// 图片域名管理 - 编辑
export function editPictureDomain(data) {
  return request({
    url: 'pictureDomain/editPictureDomain',
    method: 'post',
    data: data
  });
}
// 图片域名管理 - 状态
export function pictureUpdateStatus(data) {
  return request({
    url: 'pictureDomain/updateStatus',
    method: 'post',
    data: data
  });
}
// 查询可选择域名
export function getNotUseDomainList(data) {
  return request({
    url: 'domain/getNotUseDomainList',
    method: 'post',
    data: data
  });
}
export default {
  replaceDomain: replaceDomain,
  updateRouteStatus: updateRouteStatus,
  addDomain: addDomain,
  queryRouteList: queryRouteList,
  addRoute: addRoute,
  editRoute: editRoute,
  editDomainDeleteStatus: editDomainDeleteStatus,
  addBlackList: addBlackList,
  deleteBlackListById: deleteBlackListById,
  queryList: queryList,
  queryTemplateList: queryTemplateList,
  updateCheckTemplateById: updateCheckTemplateById,
  queryDomainList: queryDomainList,
  getDomainList: getDomainList,
  updateAutoCheckStatusById: updateAutoCheckStatusById,
  batchDomainStatusByDgulpId: batchDomainStatusByDgulpId,
  updateDomainStatusById: updateDomainStatusById,
  editDomain: editDomain,
  addScheme: addScheme,
  querySchemeList: querySchemeList,
  queryMerchantDomainList: queryMerchantDomainList,
  updateSchemeById: updateSchemeById,
  udpateSchemeStatusById: udpateSchemeStatusById,
  editDomainGroupUserLevelProvince: editDomainGroupUserLevelProvince,
  deleteSchemeById: deleteSchemeById,
  queryDomainGroupListByType: queryDomainGroupListByType,
  addDomainGroup: addDomainGroup,
  updateDomainGroupById: updateDomainGroupById,
  queryDictList: queryDictList,
  queryAreaInfo: queryAreaInfo,
  queryListByGroupId: queryListByGroupId,
  addDomainGroupList: addDomainGroupList,
  deleteDomainGroupList: deleteDomainGroupList,
  queryDeviceDictList: queryDeviceDictList,
  queryUserLevelList: queryUserLevelList,
  queryDomainListByDgulpId: queryDomainListByDgulpId,
  deleteDgulpDomainById: deleteDgulpDomainById,
  editDomainSort: editDomainSort,
  addDomainPool: addDomainPool,
  editList: editList,
  pictureQueryList: pictureQueryList,
  addPictureDomain: addPictureDomain,
  editPictureDomain: editPictureDomain,
  pictureUpdateStatus: pictureUpdateStatus,
  getNotUseDomainList: getNotUseDomainList
};