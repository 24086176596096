import "/root/.jenkins/workspace/local-vue-admin-domain.build/node_modules/core-js/modules/es6.array.iterator.js";
import "/root/.jenkins/workspace/local-vue-admin-domain.build/node_modules/core-js/modules/es6.promise.js";
import "/root/.jenkins/workspace/local-vue-admin-domain.build/node_modules/core-js/modules/es6.object.assign.js";
import "/root/.jenkins/workspace/local-vue-admin-domain.build/node_modules/core-js/modules/es7.promise.finally.js";
import Vue from 'vue';
import 'normalize.css/normalize.css'; // A modern alternative to CSS resets

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/zh-CN'; // lang i18n
import dayJs from 'dayjs';
import '@/styles/index.scss'; // global css
import api from "./api/index";
import App from "./App";
import store from "./store";
import router from "./router";
import '@/icons'; // icon
import '@/permission'; // permission control
import Clipboard from 'v-clipboard';
import defaultMixins from "./mixins";
// import mixins from './mixins'
//引入全局常量
import Constant from '@/utils/const';
Vue.use(Constant);
Vue.use(Clipboard);

/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online! ! !
 */
// import { mockXHR } from '../mock'
// if (process.env.NODE_ENV === 'production') {
//   mockXHR()
// }

// set ElementUI lang to EN
Vue.use(ElementUI, {
  locale: locale
});
Vue.config.productionTip = false;
Vue.prototype.$dayJs = dayJs;
Vue.prototype.$https = api;
// router.beforeResolve((to, from, next) => {
// 	if (store.state.user.token && store.state.user.token !== 'undefined') {
// 		next()
// 	} else {
// 		if (to.name !== 'login') {
// 			Message({
// 				message: '请登录账号！',
// 				type: 'error'
// 			})
// 			next({ name: 'login' })
// 		} else {
// 			next()
// 		}
// 	}
// })
Vue.mixin({
  mounted: function mounted() {
    if (this.onKey) {
      window.addEventListener('keyup', this.onKey.bind(this));
    }
  },
  directory: function directory() {
    if (this.onKey) {
      window.removeEventListener('keyup', this.onKey.bind(this));
    }
  }
});
new Vue({
  el: '#app',
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
});
Vue.mixin(defaultMixins);